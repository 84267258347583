import { default as _91_91slug_93_9384abyfURW1Meta } from "/data/app/pages/course/[[slug]].vue?macro=true";
import { default as indexIGRj6npRumMeta } from "/data/app/pages/index.vue?macro=true";
import { default as policyC9fXijQfBwMeta } from "/data/app/pages/policy.vue?macro=true";
import { default as indexLxL4fT71v0Meta } from "/data/app/pages/study/[[slug]]/index.vue?macro=true";
import { default as termsLXZS0xFZofMeta } from "/data/app/pages/terms.vue?macro=true";
export default [
  {
    name: _91_91slug_93_9384abyfURW1Meta?.name ?? "course-slug",
    path: _91_91slug_93_9384abyfURW1Meta?.path ?? "/course/:slug?",
    meta: _91_91slug_93_9384abyfURW1Meta || {},
    alias: _91_91slug_93_9384abyfURW1Meta?.alias || [],
    redirect: _91_91slug_93_9384abyfURW1Meta?.redirect,
    component: () => import("/data/app/pages/course/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexIGRj6npRumMeta?.name ?? "index",
    path: indexIGRj6npRumMeta?.path ?? "/",
    meta: indexIGRj6npRumMeta || {},
    alias: indexIGRj6npRumMeta?.alias || [],
    redirect: indexIGRj6npRumMeta?.redirect,
    component: () => import("/data/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policyC9fXijQfBwMeta?.name ?? "policy",
    path: policyC9fXijQfBwMeta?.path ?? "/policy",
    meta: policyC9fXijQfBwMeta || {},
    alias: policyC9fXijQfBwMeta?.alias || [],
    redirect: policyC9fXijQfBwMeta?.redirect,
    component: () => import("/data/app/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: indexLxL4fT71v0Meta?.name ?? "study-slug",
    path: indexLxL4fT71v0Meta?.path ?? "/study/:slug?",
    meta: indexLxL4fT71v0Meta || {},
    alias: indexLxL4fT71v0Meta?.alias || [],
    redirect: indexLxL4fT71v0Meta?.redirect,
    component: () => import("/data/app/pages/study/[[slug]]/index.vue").then(m => m.default || m)
  },
  {
    name: termsLXZS0xFZofMeta?.name ?? "terms",
    path: termsLXZS0xFZofMeta?.path ?? "/terms",
    meta: termsLXZS0xFZofMeta || {},
    alias: termsLXZS0xFZofMeta?.alias || [],
    redirect: termsLXZS0xFZofMeta?.redirect,
    component: () => import("/data/app/pages/terms.vue").then(m => m.default || m)
  }
]