function last(array: any): any {
    const arrayValue = isRef(array) ? array.value : array;
    return arrayValue?.slice(-1)[0];
}

function first(array: any) {
    if (!array) return null;

    const arrayValue = isRef(array) ? array.value : array;
    return arrayValue[0];
}

function existsValues(array: any[]) {
    if (!array || !Array.isArray(array)) return null;
    return array?.filter((el: any) => el);
}

function getSafeIndex(array: any[], index: number): number {
    if (index < 0 || array.length == 0) return 0;
    if (index >= array.length) return array.length - 1;

    return index;
}

function calcGroups(array: any[]) {
    const groups: any = {};

    array.map(propValue => {
        if (!groups[propValue])
            groups[propValue] = 0;

        groups[propValue]++;
    });

    return Object.entries(groups);
}

function findGroups(array: any[]) {
    const groups: any = {};

    array.map((propValue, index) => {
        if (!groups[propValue])
            groups[propValue] = [];

        groups[propValue].push(index);
    });

    return Object.entries(groups);
}

function compareStrings(a: any, b: any, predicate: ((p: any) => string) = p => p) {
    const aValue = predicate(a);
    const bValue = predicate(b);

    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
}

function swapElements(array: any[], index1: number, index2: number) {
    if (!Array.isArray(array)) throw Error('Not an array: ' + array);

    array[index2] = array.splice(index1, 1, array[index2])[0];
}

export function groupMapBy(array: any[], predicate: (p: any) => any) {
    const groups: any = {};

    array.map((propValue, index) => {
        const predicateValue = predicate(propValue);

        if (!groups[predicateValue])
            groups[predicateValue] = [];

        groups[predicateValue].push(propValue);
    });

    return groups;
}

export function groupBy(array: any[], predicate: (p: any) => any) {
    const groups: any = {};

    array.map((propValue, index) => {
        const predicateValue = predicate(propValue);

        if (!groups[predicateValue])
            groups[predicateValue] = [];

        groups[predicateValue].push(propValue);
    });

    return Object.entries(groups) as any[];
}

export function replaceBy(array: any[], elem: any, predicate: (p: any, index: number) => void) {
    const elemIndex = array.findIndex((el, index) => predicate(el, index))
    if (elemIndex != -1)
        array.splice(elemIndex, 1, elem);
}

export function lazyLoad(bufferColl: any[], allColl: any[], pageSize: number) {
    const lastIndex = bufferColl.length;
    const elements = allColl.slice(
        lastIndex,
        lastIndex + pageSize
    );

    return elements;
}

export function lazyUnload(bufferColl: any[], allColl: any[], pageSize: number) {
    if (bufferColl.length < pageSize) return allColl.slice(0, pageSize);

    bufferColl.splice(bufferColl.length - pageSize, pageSize);
}

export {
    first,
    last,
    existsValues,
    getSafeIndex,
    calcGroups,
    findGroups,
    compareStrings,
    swapElements
}