import { defineNuxtPlugin } from '#app/nuxt'
import { LazyVCourseDetails, LazyVCourseDetailsLessons, LazyVFooter, LazyVHeader, LazyVBtn, LazyEffects, LazyVCheckbox, LazyVChip, LazyVDialog, LazyDialog, LazyVEmpty, LazyVError, LazyVForm, LazyVLabel, LazyVLoader, LazyVLoaderLarge, LazyVLoaderRing, LazyVMenu, LazyMenuUtils, LazyVPasswordField, LazyVCodeButton, LazyVCountriesDialog, LazyVPhoneField, LazyDefaults, LazyVRadioField, LazyVRegionDialog, LazyVRegionField, LazyVSegment, LazyVSelect, LazySelectUtils, LazyVSvg, LazyVSwitch, LazyVTab, LazyVTabLabel, LazyVTabs, LazyVTabsLabels, LazyUseTabs, LazyVTextArea, LazyVTextField, LazyVToast, LazyVToolbar, LazyVChangePasswordDialog, LazyVLogoSvg, LazyVMenuMobileRightDialog, LazyVRegistrSuccessDialog, LazyVSelectLanguage, LazyVUserInformDialog, LazyVUserRecoverPassDialog, LazyVUserRecoverPassSuccessDialog, LazyVLoginForm, LazyVRegistrForm, LazyVResetPasswordForm, LazyVUserMenu, LazyVMenuMobileLeftDialog, LazyVPrivacyPolicyDialog, LazyVTermsOfUseDialog, LazyVPolicyEn, LazyVPolicyRU, LazyVPolicyUA, LazyVTermsOfUseEN, LazyVTermsOfUseRU, LazyVTermsOfUseUA, LazyVAbout, LazyVBanner, LazyVCourses, LazyVCoursesFilter, LazyVRequestForm, LazyVRequestFormWithEmail } from '#components'
const lazyGlobalComponents = [
  ["VCourseDetails", LazyVCourseDetails],
["VCourseDetailsLessons", LazyVCourseDetailsLessons],
["VFooter", LazyVFooter],
["VHeader", LazyVHeader],
["VBtn", LazyVBtn],
["Effects", LazyEffects],
["VCheckbox", LazyVCheckbox],
["VChip", LazyVChip],
["VDialog", LazyVDialog],
["Dialog", LazyDialog],
["VEmpty", LazyVEmpty],
["VError", LazyVError],
["VForm", LazyVForm],
["VLabel", LazyVLabel],
["VLoader", LazyVLoader],
["VLoaderLarge", LazyVLoaderLarge],
["VLoaderRing", LazyVLoaderRing],
["VMenu", LazyVMenu],
["MenuUtils", LazyMenuUtils],
["VPasswordField", LazyVPasswordField],
["VCodeButton", LazyVCodeButton],
["VCountriesDialog", LazyVCountriesDialog],
["VPhoneField", LazyVPhoneField],
["Defaults", LazyDefaults],
["VRadioField", LazyVRadioField],
["VRegionDialog", LazyVRegionDialog],
["VRegionField", LazyVRegionField],
["VSegment", LazyVSegment],
["VSelect", LazyVSelect],
["SelectUtils", LazySelectUtils],
["VSvg", LazyVSvg],
["VSwitch", LazyVSwitch],
["VTab", LazyVTab],
["VTabLabel", LazyVTabLabel],
["VTabs", LazyVTabs],
["VTabsLabels", LazyVTabsLabels],
["UseTabs", LazyUseTabs],
["VTextArea", LazyVTextArea],
["VTextField", LazyVTextField],
["VToast", LazyVToast],
["VToolbar", LazyVToolbar],
["VChangePasswordDialog", LazyVChangePasswordDialog],
["VLogoSvg", LazyVLogoSvg],
["VMenuMobileRightDialog", LazyVMenuMobileRightDialog],
["VRegistrSuccessDialog", LazyVRegistrSuccessDialog],
["VSelectLanguage", LazyVSelectLanguage],
["VUserInformDialog", LazyVUserInformDialog],
["VUserRecoverPassDialog", LazyVUserRecoverPassDialog],
["VUserRecoverPassSuccessDialog", LazyVUserRecoverPassSuccessDialog],
["VLoginForm", LazyVLoginForm],
["VRegistrForm", LazyVRegistrForm],
["VResetPasswordForm", LazyVResetPasswordForm],
["VUserMenu", LazyVUserMenu],
["VMenuMobileLeftDialog", LazyVMenuMobileLeftDialog],
["VPrivacyPolicyDialog", LazyVPrivacyPolicyDialog],
["VTermsOfUseDialog", LazyVTermsOfUseDialog],
["VPolicyEn", LazyVPolicyEn],
["VPolicyRU", LazyVPolicyRU],
["VPolicyUA", LazyVPolicyUA],
["VTermsOfUseEN", LazyVTermsOfUseEN],
["VTermsOfUseRU", LazyVTermsOfUseRU],
["VTermsOfUseUA", LazyVTermsOfUseUA],
["VAbout", LazyVAbout],
["VBanner", LazyVBanner],
["VCourses", LazyVCourses],
["VCoursesFilter", LazyVCoursesFilter],
["VRequestForm", LazyVRequestForm],
["VRequestFormWithEmail", LazyVRequestFormWithEmail],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
