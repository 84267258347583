
async function loadGeoloc() {
    return new Promise((resolve, reject) => {
       const xhr = new XMLHttpRequest();
       xhr.open("GET", 'https://ipapi.co/json');
       xhr.onload = (e: any) => {
          const json = JSON.parse(xhr.responseText);
          resolve(json)
       }

       xhr.send();
    });
}
 
export {
    loadGeoloc
}