import { transl } from "../useTranslation";

const isToastActive = ref(false)
const toastMessage = ref('Sample message');
const toastType = ref<any>(undefined);
const toastOptions = ref<any>(undefined);

function toast(text: string, options: any) {
    toastMessage.value = transl(text);
    toastType.value = options?.type.toLowerCase();
    toastOptions.value = options;
    isToastActive.value = false;

    setTimeout(() => isToastActive.value = true);
}

function toastSuccess(text: string, options: any) {
    toast(text, { type: 'success', ...options })
}

function toastError(text: string, options: any) {
    toast(text, { type: 'error', ...options })
}

function toastWarning(text: string, options: any) {
    toast(text, { type: 'warning', ...options })
}

function toastInfo(text: string, options: any) {
    toast(text, { type: 'info', ...options })
}

export {
    isToastActive,
    toastMessage,
    toastType,
    toastOptions,
    toast,
    toastSuccess,
    toastError,
    toastWarning,
    toastInfo
}