<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { createRipple } from "./effects";
import { cssProp } from "@/utils/css";

const props = defineProps([
  "width",
  "height",
  "padding",
  "rounded",
  "loading",
  "disabled",
  "color",
  "bgColor",
  "value",
]);

const emit = defineEmits(["click"]);
const attrs = useAttrs();

const computedStyle = computed(() => ({
  width: cssProp(props.width),
  height: cssProp(props.height),
  rounded: cssProp(props.rounded),
  padding: cssProp(props.padding),
  color: cssProp(props.color),
  backgroundColor: cssProp(props.bgColor),
}));

const computedClass = computed(() => [
  "icon" in attrs ? `--icon size-${attrs.icon || "md"}` : "",
  "neon" in attrs ? "neon" : "ripple",
  "primary" in attrs ? "primary" : "",
  "float" in attrs ? "float" : "",
  active.value ? "active" : "",
]);

function handleClick(e) {
  if (computedClass.value.includes("ripple")) {
    createRipple(e);
    e.stopPropagation();
  }

  if (props.disabled) return;

  emit("click");

  //if available button group
  buttonGroup?.activate(groupEl);
}

// buttonGroup
const buttonGroup = inject("buttonGroup", null);
const active = ref(false);
const groupEl = {
  buttonValue: attrs.value || props.value,
  activeState: active,
};

onMounted(() => buttonGroup?.register(groupEl));
onUnmounted(() => buttonGroup?.unregister(groupEl));
</script>
<template>
  <div
    @click.stop="handleClick($event)"
    class="v-btn"
    :class="computedClass"
    :style="computedStyle"
    :disabled="props.disabled"
    v-bind="attrs"
  >
    <slot></slot>
    <slot name="loader">
      <v-loader v-if="loading" class="ml-2" size="20"></v-loader>
    </slot>
  </div>
</template>

<style>
.v-btn {
  display: inline-flex;
  /* align-self: flex-start; */
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;

  padding: 0.6rem 1.6rem;
  background-color: var(--background-hover);
  color: var(--text-color, #000);
  border-radius: 0.45rem;
  font-size: 18px;
  position: relative;
  overflow: hidden;
}

.v-btn[disabled="true"] {
  opacity: 0.5;
}

.v-btn:hover {
  opacity: 0.8;
}

.primary {
  color: var(--background);
  background-color: var(--button-bg);
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 500ms linear;
  background-color: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  overflow: hidden;
}

@keyframes ripple {
  to {
    transform: scale(5);
    opacity: 0;
  }
}

.neon {
  overflow: inherit;
  position: relative;
  border: 1px solid transparent;
  color: #f4f4f5;
  border-radius: 0.5rem;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.neon::before {
  content: "";
  position: absolute;
  background-color: var(--button-chat-send-end);
  inset: 0px;
  z-index: -2;
  border-radius: 9.5px;
}

.neon::after {
  transition: all 0.2s ease 0s;
  content: "";
  position: absolute;
  background: linear-gradient(
    to right,
    rgb(0, 220, 130),
    rgb(54, 228, 218),
    rgb(22, 167, 158)
  );
  inset: -3px;
  z-index: -3;
  border-radius: 9.5px;
}

.neon:hover {
  background-color: black;
  opacity: 1;
}

.neon:hover::after {
  box-shadow: rgba(0, 220, 130, 0.5) 0px 0px 10px 0px,
    rgba(54, 228, 218, 0.5) 0px 0px 20px 0px;
}

/* pink */
.neon.pink::after {
  background: linear-gradient(
    to right,
    hsl(296deg 100% 68.95%),
    hsl(310 76% 55% / 1),
    hsl(334.58deg 77% 57%)
  );
}

.neon.pink:hover::after {
  box-shadow: rgb(189 0 220 / 50%) 0px 0px 10px 0px,
    rgb(228 54 205 / 50%) 0px 0px 20px 0px;
}

/* yellow */
[neon="yellow"]::after {
  background: linear-gradient(to right, rgb(220 168 0), rgb(226 127 67), rgb(210 86 0));
}

[neon="yellow"]:hover::after {
  box-shadow: rgb(220 109 0 / 50%) 0px 0px 10px 0px,
    rgb(228 225 54 / 50%) 0px 0px 20px 0px;
}

.create-button {
  background: var(--create-button);
  padding-left: 0.75rem;
  padding-right: 1.25rem;
  /* border-radius: 1.25rem; */
  padding: 0.7rem 1rem;
  max-width: 240px;
  display: block;
  margin: 30px auto;
  background: var(--button-bg);
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.create-button .v-icon {
  margin-right: 6px;
}

/* flat */

.v-btn[flat] {
  background-color: transparent;
}

/* icon */

.v-btn[icon] {
  background-color: transparent;
  border-radius: 50%;
}

.v-btn[icon]:hover {
  background-color: var(--item-hover);
}

.--icon {
  padding: 0rem !important;
  color: var(--icon);
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.size-2xs {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.size-xs {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.size-sm {
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
}

.size-md {
  min-width: 55px;
  max-width: 55px;
  min-height: 55px;
  max-height: 55px;
}

.size-lg {
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
}

[xs] {
  max-width: 40px;
  padding: 4px 8px;
}

[sm] {
  max-width: 80px;
  padding: 6px 12px;
}

[md] {
  max-width: 100px;
  padding: 8px 15px;
}

[lg] {
  max-width: 140px;
  padding: 12px 30px;
}

.float {
  position: fixed;
  right: 10px;
  bottom: 15px;
  border-radius: 50%;
  z-index: 100;
  color: #fff;
  background-color: var(--button-float-bg) !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  aspect-ratio: 1;
}

.float[icon]:hover {
  background-color: var(--button-float-bg);
  opacity: 0.8;
}
</style>
<style scoped>
.label {
  position: absolute;
}
</style>
@/utils/css