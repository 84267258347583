import { createTransl, createTranslKey, deleteTransl, loadDictionary, loadLocales, loading, updateTranslKey, searchTransl, updateTransl, deleteTranslKey, loadTranslKey, exportTransl, exportEmptyTransl } from './translation.api';

const activeLocale = ref('en');
const activeTransl = computed(() => localesColl.value.find(lang => lang.localization_locale == activeLocale.value))
const loadedDictionaries = ref({} as any);
const localesColl = ref<any[]>([] as any);
const localesMap = ref({} as any);
const hasDictionaries = computed(() => Object.values(loadedDictionaries.value).length > 0)
const ready = ref(false)

async function setLocale(locale: string) {
   if (!locale) return;

   await loadLocale(locale);

   activeLocale.value = locale;
}

async function loadLocale(locale: string) {
   const dictionaryIsLoaded = loadedDictionaries.value[locale] !== undefined;

   if (!dictionaryIsLoaded) {
      const dictionary = await loadDictionary(locale) || {};
      loadedDictionaries.value[locale] = dictionary.dic;
   }
}

function transl(key: string, locale?: string) {

   //if dictionary is loading or empty
   if (!hasDictionaries.value)
      return key;

   if (locale) {
      const dictionary = loadedDictionaries.value[locale];
      return dictionary !== undefined ? dictionary[key] : key;
   }

   const dictionary = loadedDictionaries.value[activeLocale.value];

   if (dictionary && dictionary[key])
      return dictionary[key];

   return key;
}

async function fetchTransl() {
   const localesObject: any[] = await loadLocales() || [];
   localesColl.value = Object.values(localesObject);
   localesMap.value = Object.fromEntries(localesObject.map(l => ([l.localization_locale, l])));
   ready.value = true;
}

function getDictionary(locale: string) {
   return loadedDictionaries.value[locale];
}

function updateDictionary(translModel: any) {
   loadedDictionaries.value[translModel.locale][translModel.key] = translModel.value;
}

export function useTranslation() {
   return {
      ready,
      loading,
      activeLocale,
      activeTransl,
      localesColl,
      localesMap,
      fetchTransl,
      searchTransl,
      createTransl,
      updateTransl,
      deleteTransl,
      exportTransl,
      exportEmptyTransl,
      createTranslKey,
      updateTranslKey,
      deleteTranslKey,
      loadTranslKey,
      transl,
      setLocale,
      loadLocale,
      getDictionary,
      updateDictionary,
   }
}

export { 
   transl,
   setLocale,
   loadLocale,
   getDictionary,
   ready,
   activeLocale,
   activeTransl,
   localesColl,
   localesMap
}