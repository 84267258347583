import { loadGeoloc } from "./geoloc.api";

const geoloc = reactive<any>({
    countryCode: '',
    countryIso: '',
    languages: []
})

export function useGeoloc() {

    function parseLanguages(languageString: string) {
        if (!languageString) return [];
        const languages = languageString.split(',').map(l => l.slice(0,2));
        return languages;
    }

    async function fetchGeo() {
        if (geoloc.countryCode) return;

        const {
            country_calling_code,
            languages,
            country }: any
            = await loadGeoloc();

        geoloc.languages = parseLanguages(languages);
        geoloc.countryCode = country_calling_code.match(/\d+/)[0];
        geoloc.countryIso = country.toLowerCase()
    }

    return {
        fetchGeo,
        geoloc
    }
}