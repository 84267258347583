<script setup>
import { cssProp } from "@/utils/css";

const attrs = useAttrs();
const sizeObject = {
  ...cssProp("width", attrs.size, "20px"),
  ...cssProp("height", attrs.size, "20px"),
};
const styleObject = reactive({ ...sizeObject });
</script>
<template>
  <div class="v-loader">
    <!-- <div class="container" > -->
      <!-- <font-awesome-icon
        icon="fa-solid fa-spinner"
        class="spinner2"
        :style="styleObject"
      /> -->
    <!-- </div> -->
    <slot></slot>
  </div>
</template>

<style scoped>
.v-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* height: 100%; */
}
.container {
  margin: 3px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 30px; */
  /* width: 30px; */
}
.spinner2 {
  animation: rotate 0.8s linear infinite;
  z-index: 2;
  position: relative;
  left: 0;
  top: 0;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
}
.spinner .path {
  stroke: #a5fff6fd;
  stroke-linecap: round;
  animation: dash 0.9s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
@/utils/css