import { useAxios } from '@/composables/useAxios'

const { axios, loading } = useAxios();

async function loadCourses({ accountId }: any) {
    const params = {
        accountId
    };
    const { courses } = await axios.post(`/courses/list`, params) || { courses: [] };
    return courses;
}

async function loadCourseDetails({ courseId, courseUrlToken }: any) {
    const params = { courseId, courseUrlToken };
    const { course } = await axios.post(`/courses/details`, params);
    return course;
}

export {
    loadCourses,
    loadCourseDetails,
    loading
}