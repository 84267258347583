<script setup>
const optionsDefaults = {
  autoclose: true,
};

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "Info",
  },
  options: {
    type: Object,
    default: {
      autoclose: true,
    },
  },
});

const emit = defineEmits(["update:modelValue"]);
const attrs = useAttrs();

const options = computed(() => ({ ...optionsDefaults, ...props.options }));
const computedClass = computed(() => ({
  active: props.modelValue,
}));

watch(
  () => props.modelValue,
  (val) => {
    //timeout for toast
    if (val === true) {
      if (options.value.autoclose)
        setTimeout(() => emit("update:modelValue", false), 5000);
    }
  },
  { immediate: true }
);

function close() {
  emit("update:modelValue", false);
}
</script>
<template>
  <div class="v-toast" :class="computedClass" v-bind="attrs">
    <div class="left-border-el"></div>
    <div class="ml-2">
      <div class="title-el">{{ type }}</div>
      <div><slot></slot></div>
    </div>
    <div>
      <v-btn icon="xs" @click="close()">
        <div class="flex-inline">
          <v-svg src="/icons/close-svgrepo-com.svg" :options="{ width: '20px' }" />
        </div>
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.v-toast {
  display: grid;
  grid-template-columns: 10px 1fr 30px;
  grid-gap: 12px;

  position: fixed;
  top: 0;
  left: 50%;
  padding: 10px 10px 10px 10px;
  opacity: 0.3;
  z-index: 9000;

  /* transition */
  transform: translate3d(-50%, -100%, 0) scale(0);
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: transform, scale, opacity;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

  font-size: 16px;
  border-radius: 10px;
  background-color: #fff;

  white-space: normal;
  width: max-content;
  max-width: 90%;
  line-height: 36px;
}

.active {
  opacity: 1;
  transform: translate3d(-50%, 30px, 0) scale(1);
}

.left-border-el {
  height: 100%;
  width: 7px;
  border-radius: 10px;
}

.title-el {
  font-weight: 600;
  text-transform: capitalize;
}
</style>

<style scoped>
.error .left-border-el {
  background-color: #ef5350;
}
.error .title-el {
  color: #ef5350;
}

.success .left-border-el {
  background-color: #7aae40;
}
.success .title-el {
  color: #7aae40;
}

.warning .left-border-el {
  background-color: #ffca27;
}
.warning .title-el {
  color: #ffca27;
}

.info .left-border-el {
  background-color: #2788ff;
}
.info .title-el {
  color: #2788ff;
}
</style>
