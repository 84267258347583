import { loadCourseDetails, loadCourses, loading } from "@/composables/useCourses/courses.api";

const loadedCourses = shallowRef<any[]>([]);

export function useCourses() {

    const filter = reactive<any>({
        onlyIds: null,
        onlyLocales: null,
        onlyCategories: null,
        publishedState: null,
        onSearch: null,
    })

    const byId = (c: any) => filter.onlyIds.includes(c._id)
    const byLocale = (c: any) => filter.onlyLocales.includes(c.course_locale)
    const byCategory = (c: any) => filter.onlyCategories.includes(c.course_category)
    const byStatus = (c: any) => c.course_is_published == filter.publishedState

    const coursesColl = ref<any>([]);

    function updateColl() {

        if (!loadedCourses.value) return [];

        // console.log('FILTER:', JSON.stringify(filter, null, '  '))
        let coll = loadedCourses.value.slice();

        if (filter.onlyIds?.length)
            coll = coll.filter(byId);

        if (filter.onlyLocales?.length)
            coll = coll.filter(byLocale);

        if (filter.onlyCategories?.length)
            coll = coll.filter(byCategory);

        if (filter.publishedState != null)
            coll = coll.filter(byStatus);

        if (filter.onSearch != null)
            coll = coll.filter(c => filter.onSearch(c));

            coursesColl.value = coll;
    };

    watch(
        () => [filter, loadedCourses.value],
        () => updateColl(),
        { immediate: true, deep: true }
    )

    const categoriesColl = computed(() => {
        const coll: any[] = [...loadedCourses.value];
        const uniqCategoriesColl = [...new Set(coll.map(c => c.course_category))]

        return uniqCategoriesColl;
    })

    function setCoursesFilter(filterValue: any) {
        filter.onlyIds = filterValue.onlyIds || null;
        filter.onlyLocales = existsValues(filterValue.onlyLocales) || null;
        filter.onlyCategories = existsValues(filterValue.onlyCategories) || null;
        filter.publishedState = filterValue.publishedState != null ? filterValue.publishedState : null;
        filter.onSearch = filterValue.onSearch != null ? filterValue.onSearch : null;
    }

    async function fetchCourses(params: any = {}) {
        loadedCourses.value = await loadCourses(params);
    }

    function findById(courseId: string) {
        return loadedCourses.value?.find((c: any) => c._id == courseId) || null;
    }


    return {
        fetchCourses,
        findById,
        loadCourseDetails,
        setCoursesFilter,
        updateColl,
        loadedCourses,
        coursesColl,
        categoriesColl,
        loading
    }
}