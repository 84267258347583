export function parseOrigins(originsString: string) {
    let originX = originsString.match(/left|right/i) || "left";
    let originY = originsString.match(/top|bottom/i) || "top";

    return {
        x: originX[0],
        y: originY[0]
    }

}
export function getSuitableOrigins({ activatorEl, popupEl, defaultOrigins }: any) {

    const activatorBounds = activatorEl.getBoundingClientRect();
    const screenBounds = document.body.getBoundingClientRect();
    const origins = { ...defaultOrigins };

    if (activatorBounds.left + popupEl.offsetWidth > screenBounds.width)
        if (activatorBounds.left + popupEl.offsetWidth > screenBounds.width * 0.85)
            origins.x = "right";

    if (activatorBounds.bottom + popupEl.offsetHeight > screenBounds.height)
        if (activatorBounds.bottom + popupEl.offsetHeight > screenBounds.height * 0.85)
            origins.y = "bottom";

    return origins;
}

export function getSuitablePosition({ activatorEl, popupEl, origins }: any) {

    const shiftLeft = activatorEl.offsetWidth - popupEl.offsetWidth;
    const shiftTop = -popupEl.offsetHeight; //+ activatorEl.offsetHeight;

    let offsetX = 0,
        offsetY = 0;

    const activatorBounds = activatorEl.getBoundingClientRect();
    // const activatorChildStyle = getComputedStyle(activatorEl.firstElementChild);
    // const childMargins = parseMargins(activatorChildStyle);

    if (origins.x == "left") offsetX = 0;
    if (origins.x == "right") offsetX = shiftLeft;
    if (origins.y == "top") offsetY = activatorBounds.height;
    if (origins.y == "bottom") offsetY = shiftTop;

    const scrollableParent = getScrollParent(activatorEl) != document.documentElement;
    const scrollableShiftY = scrollableParent ? window.scrollY : 0
    const scrollableShiftX = scrollableParent ? window.scrollX : 0

    const offsetTop = scrollableShiftY + offsetY;
    const offsetLeft = scrollableShiftX + offsetX;

    return {
        top: offsetTop,
        left: offsetLeft,
    };
}

const isScrollable = (node: Element) => {
    if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return false
    }
    const style = getComputedStyle(node)
    return ['overflow', 'overflow-x', 'overflow-y'].some((propertyName) => {
        const value = style.getPropertyValue(propertyName)
        return value === 'auto' || value === 'scroll'
    })
}

export const getScrollParent = (node: Element): Element => {
    let currentParent = node.parentElement
    while (currentParent) {
        if (isScrollable(currentParent)) {
            return currentParent
        }
        currentParent = currentParent.parentElement
    }
    return document.scrollingElement || document.documentElement
}

function parseMargins(computedStyle: any) {
    const bottom = parseInt(computedStyle.marginBottom);
    const top = parseInt(computedStyle.marginTop);
    const left = parseInt(computedStyle.marginLeft);
    const right = parseInt(computedStyle.marginRight);

    return {
        left, top, right, bottom
    }
}