
import { byTransl } from "../useLanguages/languages.utils";
import { allCategoriesColl } from "./categories.constants";

const filter = reactive<any>({
    categoryValues: [],
})

export function useCategories() {

    const categoriesColl = computed(() => {
        let coll = allCategoriesColl as any[];

        //filter locales
        if (filter.categoryValues.length)
            coll = coll?.filter((l: any) => filter.categoryValues?.find((c: any) => c == l.label))

        coll.sort((a, b) => byTransl(a, b, p => p.label));

        return coll;
    })


    function setCategoryFilter(filterValue: any) {
        filter.categoryValues = filterValue.categoryValues || [];
    }

    function findById(categoryId: string) {
        const category = categoriesColl.value.find( c => c.id == categoryId); 
        return category;
    }

    return {
        categoriesColl,
        setCategoryFilter,
        findById
    }
}