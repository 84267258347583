import { NUXT_APP_LOCALIZATION_API } from '~/env';
import { useAxios } from '../useAxios';

// const translationApi = VITE_APP_LOCALIZATION_API;
const translationApi = NUXT_APP_LOCALIZATION_API; //import.meta.env.VITE_APP_LOCALIZATION_API;

const { axios, loading } = useAxios();

async function loadLocales() {
    const { locales } = await axios.get(translationApi + `/localization/locales`);
    return locales;
}

async function createTransl(translModel: any) {
    const { _id, ...modelValues } = translModel;
    const { locales } = await axios.post(translationApi + `/localization/create`, modelValues);
    return locales;
}

async function deleteTransl(_id: string) {
    const { deletedCount } = await axios.post(translationApi + `/localization/delete/${_id}`);
    return deletedCount > 0;
}

async function updateTransl(translModel: any) {
    const { documentsCount } = await axios.post(translationApi + `/localization/update`, translModel);
    return documentsCount > 0;
}

async function loadDictionary(locale: string) {
    const { locales } = await axios.get(translationApi + `/localization/dics/${locale}`);
    return locales;
}

async function searchTransl(text: string) {
    const { searchResults } = await axios.get(translationApi + `/localization/search/${text}`);
    return searchResults;
}

async function updateTranslKey(wordModel: any) {
    const success = await axios.post(translationApi + `/localization/saveword`, wordModel);
    return success;
}

async function createTranslKey(keyModel: any) {
    const success = await axios.post(translationApi + `/localization/addword`, keyModel);
    return success;
}

async function deleteTranslKey(keyModel: any) {
    const success = await axios.post(translationApi + `/localization/deletekey`, keyModel);
    return success;
}

async function loadTranslKey(keyModel: any) {
    const { transl } = await axios.post(translationApi + `/localization/translkey`, keyModel);
    return transl;
}

async function exportTransl(locale: string) {
    const options = { responseType: "blob", raw: true };
    const { data, headers } = await axios.get(translationApi + `/localization/exportxls/${locale}`, options);
    return { data, headers };
}

async function exportEmptyTransl(locale: string) {
    const options = { responseType: "blob", raw: true };
    const { data, headers } = await axios.get(translationApi + `/localization/exportemptyxls/${locale}`, options);
    return { data, headers };
}

async function importTransl(locale: string, excelFile: any) {

    const formData = new FormData();
    formData.append("file", excelFile);

    const formOptions = {
        headers: { "Content-Type": "multipart/form-data" },
    };

    const success = await axios.post(translationApi + `/localization/importxls/${locale}`, formData, formOptions);
    return success;
}


export {
    loading,
    loadLocales,
    loadDictionary,
    searchTransl,
    createTransl,
    updateTransl,
    deleteTransl,
    exportTransl,
    exportEmptyTransl,
    importTransl,
    createTranslKey,
    updateTranslKey,
    deleteTranslKey,
    loadTranslKey
}