import { loading, lookupAccount } from "./accounts.api";

const activeAccount = ref<any>(null);

export function useAccount() {

    async function fetchAccount({ _id, accountName, accountToken, hostName, countryIso }: any) {
        const { account } = await lookupAccount({
            _id,
            accountName,
            accountToken,
            hostName,
            countryIso
        });

        activeAccount.value = account || null;
    }

    return {
        fetchAccount,
        lookupAccount,
        activeAccount,
        loading,
    }
}

