import { useAxios } from '@/composables/useAxios'

const { axios, loading } = useAxios();

export async function loadUserAccounts(): Promise<any[]> {
    const { accounts } = await axios.get(`/auth2/accounts`);
    return accounts;
}

async function lookupAccount({ _id, accountName, accountToken, hostName, countryIso }: any) {
    const params = {
        _id,
        accountName,
        accountToken,
        hostName,
        countryIso
    };
    const { account } = await axios.post(`/accounts/lookup`, params) || {};
    return { account };
}

export {
    lookupAccount,
    loading
}