import axios from 'axios'
import { ref } from 'vue'
import {
    NUXT_APP_LANDING_API
} from '~/env';

const loading = ref(false);

const axiosGlobal = axios.create({
    baseURL: NUXT_APP_LANDING_API,
})

const wrapRequest = (requestMethod: any, params: any) => {
    loading.value = true;

    return requestMethod(...params)
        .then(({ data }: any) => {
            loading.value = false;

            // if (data.dataEnc) {
            // const dataDec = decrypt(data.dataEnc, NUXT_APP_CRYPTO_KEY_PUBLIC);
            // const dataJson = JSON.parse(dataDec);

            // return dataJson;
            // }

            return data
        })
        .catch((e: any) => {
            loading.value = false;
            return e.response.data;
        });
}

export function useAxios() {

    const axiosWrapper = {
        get: (...params: any) => wrapRequest(axiosGlobal.get, params),
        post: (...params: any) => wrapRequest(axiosGlobal.post, params),
        put: (...params: any) => wrapRequest(axiosGlobal.put, params),
        delete: (...params: any) => wrapRequest(axiosGlobal.delete, params),
    }

    return {
        axios: axiosWrapper,
        axiosGlobal,
        loading
    }
}