let NUXT_APP_LANDING_API = "https://landing-api.bibleawr.com";
let NUXT_APP_IMAGES_API = "https://study-api.bibleawr.com";
let NUXT_APP_STUDENT_UI = "https://study.bibleawr.com";
let NUXT_APP_LOCALIZATION_API = "https://api.bibleawr.com";
let NUXT_APP_BROADCAST_API = "https://broadcast-api.bibleawr.com";

if (process.dev) {
    // NUXT_APP_LANDING_API = "http://localhost:3060";
    // NUXT_APP_STUDENT_UI = "http://localhost:4200";

}

export {
    NUXT_APP_LANDING_API,
    NUXT_APP_LOCALIZATION_API,
    NUXT_APP_STUDENT_UI,
    NUXT_APP_IMAGES_API,
    NUXT_APP_BROADCAST_API
}