export function useTabs() {
    const tabsColl = ref<any[]>([]);

    function activate(tabValue: any) {
        tabsColl.value.map((tab, index) => {
            tab.value.isTabActive = tab.value.tabValue == tabValue
        });
    }

    function register(tabChild: any) {
        tabsColl.value.push(tabChild);
    }

    function unregister(tabChild: any) {
        const index = tabsColl.value.findIndex(e => e == tabChild);
        tabsColl.value.splice(index, 1);
    }

    return {
        tabsColl,
        activate,
        register,
        unregister,
    }
}